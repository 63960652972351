import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.scss"

const Home = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="d-flex flex-column w-100">
        <div className="container">
          <h1 className="registration-black">
          Currently Working On
          </h1>
          <h4 className="charcoal">Medeo - Your connection to your healthcare provider</h4>
          <h4 className="charcoal">Craysh - a way for me to teach my son things using a tool I have made</h4>
        </div>
        <div className="flex-fill row page-bg bg-clip"></div>
      </div>
    </Layout>
  )
}

export default Home
